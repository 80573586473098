export class MulticlientCreateTarget {
  public id: number;

  public creator_id: string;
  public creator_name: string;
  public reciever_id: string;
  public reciever_name: string;

  public created_at: string;
  public updated_at: string;
  public errors?: any = {};
}
