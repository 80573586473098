<div id="customers-link-form" tabindex="-1" class="modal fade colored-header-primary" role="dialog">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header modal-header-colored d-block">
        <header class="d-flex">
          <h3 class="modal-title text-white" id="exampleModalLabel">Vincular clientes</h3>
          <button type="button" class="close text-white" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </header>
      </div>
      <form (ngSubmit)="onSubmit()" novalidate class="d-flex flex-column justify-content-between">
        <section class="modal-body pt-0 customer-card d-flex m-3">
          <div class="col-6 mr-1">
            <label>Cliente criador</label>
            <select class="form-control input-select query-pos" (change)="selectCreator($event)">
              <option value="" disabled>Selecione um Cliente</option>
              <option *ngFor="let customer of customers" [value]="customer[0]">{{ customer[1] }}</option>
            </select>
          </div>
          <div class="col-6 ml-1">
            <label>Cliente recebedor</label>
            <select class="form-control input-select query-pos" (change)="selectReciever($event)">
              <option value="" disabled>Selecione um Cliente</option>
              <option *ngFor="let customer of customers" [value]="customer[0]">{{ customer[1] }}</option>
            </select>
          </div>
        </section>
        <div class="modal-footer">
          <button type="button" data-dismiss="modal" class="btn btn-secondary btn-lg md-close">Cancelar</button>
          <button type="submit" class="btn btn-primary btn-lg md-close">Enviar</button>
        </div>
      </form>
    </div>
  </div>
</div>
