import { Routes } from "@angular/router";

import { LoginComponent } from "./core/components/auth/login/login.component";
import { ForgotPasswordComponent } from "./core/components/auth/forgot-password/forgot-password.component";
import { RecoverPasswordComponent } from "./core/components/auth/recover-password/recover-password.component";

import { LoginGuard } from "./core/components/auth/login/login.guard";

export const ROUTES: Routes = [
  { path: "", redirectTo: "backoffice", pathMatch: "full" },

  { path: "backoffice/login", component: LoginComponent },
  { path: "backoffice/forgot_password", component: ForgotPasswordComponent },
  { path: "backoffice/recover/:token", component: RecoverPasswordComponent },

  {
    path: "backoffice",
    loadChildren: () => import('./backoffice/backoffice.module').then(m => m.BackofficeModule),
    canLoad: [LoginGuard],
    canActivate: [LoginGuard],
  },
];
