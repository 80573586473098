import { Component, OnInit } from "@angular/core";
import { ApiService } from "../../../core/services/api.service";
import { NotificationService } from "../../../core/messages/notification.service";
import { AppComponent } from "../../../app.component";
import { Router } from "@angular/router";
import { CoreService } from "../../../core/services/core.service";
import { Customer } from "../../../models/customer";
import { MulticlientCreateTarget } from "../../../models/multiclient_create_target";

@Component({
  selector: "focco-customers-link",
  templateUrl: "./customers-link.component.html",
})
export class CustomersLinkComponent implements OnInit {
  public register: MulticlientCreateTarget = new MulticlientCreateTarget();
  public url = "admin/multiclient_create_targets";
  public customers: Array<any> = [];
  public errors: any = {};
  public customer: Array<Customer>;

  constructor(public notificationService: NotificationService, public router: Router, public appComponent: AppComponent, public apiService: ApiService, public coreService: CoreService) {}

  ngOnInit() {
    this.appComponent.load(true);
    this.getRegisters();
  }

  getRegisters() {
    this.apiService.get("admin/customers/customers_for_select").subscribe(async (data: any) => {
      this.customers = data;
    });
  }

  public onSubmit() {
    if (this.validateAttributes()) {
      this.create();
      return;
    }
  }

  validateAttributes() {
    let result = true;
    if (!this.register.creator_id) {
      this.register.errors.creator_id = ["Identificador não pode ficar em branco."];
      result = false;
    }

    return result;
  }

  public create() {
    this.appComponent.load(true);
    this.apiService.post(this.url, this.register).subscribe(
      (resp) => {
        this.notificationService.notify(`Clientes vinculados com sucesso.`, "success");
        window["$"]("#customers-link-form").modal("hide");
        this.register.creator_id = "";
        this.register.reciever_id = "";
        this.appComponent.load(false);
      },
      (errors) => {
        this.register.errors = errors.error;
        this.notificationService.notify("Erro ao criar contrato", "danger");
        this.appComponent.load(false);
        return;
      }
    );
    this.appComponent.load(false);
  }

  public selectCreator(event) {
    this.register.creator_id = event.target.value;
  }

  public selectReciever(event) {
    this.register.reciever_id = event.target.value;
  }
}
